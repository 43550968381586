import type { HTMLAttributes } from 'react';
import Icon from '../icon/icon';

type LoadingProps = HTMLAttributes<HTMLDivElement>;

const Loading = ({ ...props }: LoadingProps) => {
  return (
    <div {...props}>
      <Icon name="loading" />
      <span className="sr-only">Loading</span>
    </div>
  );
};

Loading.displayName = 'Loading';

export default Loading;
